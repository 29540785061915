import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { IconButton, MenuItem, useTheme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SketchPicker } from 'react-color';
import {
  SELECT_TAB_TYPE,
  SELECT_TAB_TYPE_INDEX,
} from 'constants/selectTabType';
import useMobile from 'utils/hooks/useMobile';
import { updateCurrentStoryAction } from 'common/actions/storyActions';
import { storyItemsSelector } from 'common/selectors/story';
import { storyListEditSettingsSelector } from 'common/selectors/editStory';
import FormWrap from 'components/forms/FormWrap';
import SelectField from 'components/forms/SelectField';
import StyledCheckbox from 'components/forms/StyledCheckbox/StyledCheckbox';
import StyledColorInput from 'components/forms/StyledColorInput/StyledColorInput';
import { RestartIcon } from 'components/Icons/RestartIcon';
import FileInput from 'components/forms/FileInput/FileInput';
import StyledTab from 'components/StyledTab/StyledTab';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import TabPanel from 'components/TabPanel/TabPanel';
import StyledInput from 'components/forms/StyledInput/StyledInput';
import ChangeOrientationDialog from '../../Dialogs/ChangeOrientationDialog';
import ControlBarBlock from '../ControlBarBlock';
import {
  CUSTOM_ICONS,
  defaultValues,
  fonts,
} from './SettingsControl.constants';
import useStyles from './styles';

const SettingsControl = () => {
  const { spacing } = useTheme();

  const selector = createSelector(
    storyItemsSelector,
    storyListEditSettingsSelector,
    (story, settingsOpen) => ({
      story,
      settingsOpen,
    })
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSmallDesktop } = useMobile();
  const [closeDialog, setCloseDialog] = useState(false);
  const [pickerOpened, setPickerOpened] = useState(false);
  const [pickerColor, setPickerColor] = useState('');
  const [changeField, setChangeField] = useState('');
  const [selectTab, setSelectTab] = useState(0);

  const {
    story: { hideBorder, ...story },
  } = useSelector(selector);

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: story?.id || '',
      fontFamily: story?.fontFamily || defaultValues.fontFamily,
      primaryColor: story?.primaryColor || defaultValues.primaryColor,
      secondaryColor: story?.secondaryColor || defaultValues.secondaryColor,
      backgroundColor: story?.backgroundColor || defaultValues.backgroundColor,
      activeFrameBorderColor:
        story?.activeFrameBorderColor || defaultValues.activeFrameBorderColor,
      enlargeFrame: story?.enlargeFrame || false,
      backgroundAttachment:
        story?.backgroundAttachment || defaultValues.backgroundAttachment,
      closeButtonIcon: story?.closeButtonIcon || defaultValues.buttonIcon,
      restartStoryIcon: story?.restartStoryIcon || defaultValues.buttonIcon,
      backpackIcon: story?.backpackIcon || defaultValues.buttonIcon,
      journalIcon: story?.journalIcon || defaultValues.buttonIcon,
      nextButtonIcon: story?.nextButtonIcon || defaultValues.buttonIcon,
      backButtonIcon: story?.backButtonIcon || defaultValues.buttonIcon,
      languageButtonIcon: story?.languageButtonIcon || defaultValues.buttonIcon,
      hideBorder: hideBorder || false,
      showNextFrames: story?.showNextFrames || false,
      autoFullScreen: story?.autoFullScreen || true,
    },
  });

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  const changeOrientation = useCallback(() => {
    dispatch(
      updateCurrentStoryAction(story, 'isLandscape', !story.isLandscape)
    );
    setCloseDialog(false);
  }, [story, dispatch]);

  const handleOnCloseDialog = useCallback(() => {
    setCloseDialog(false);
  }, []);

  const handleOpenPicker = useCallback((e) => {
    setChangeField(e.target.name);
    setPickerColor(e.target.value);
    setPickerOpened(true);
  }, []);

  const handleResetColors = useCallback(() => {
    setValue('primaryColor', defaultValues.primaryColor);
    setValue('secondaryColor', defaultValues.secondaryColor);
    setValue('backgroundColor', defaultValues.backgroundColor);
    setValue('activeFrameBorderColor', defaultValues.activeFrameBorderColor);
    const {
      orientation,
      fontFamily,
      backgroundAttachment, // reset colors only; maintain other fields
      enlargeFrame,
      isHiddenIndicator,
      ...newFields
    } = defaultValues;
    const updatedStory = { ...story, ...newFields };
    dispatch(updateCurrentStoryAction(updatedStory));
  }, [setValue, dispatch, story]);

  useEffect(() => {
    if (story?.backgroundAttachment?.type) {
      setSelectTab(SELECT_TAB_TYPE_INDEX[story?.backgroundAttachment?.type]);
    }
  }, [story]);

  const handleUpload = useCallback(
    (data, name) => {
      setValue(name, data);
      dispatch(updateCurrentStoryAction(story, name, data));
    },
    [dispatch, setValue, story]
  );

  const handleDelete = useCallback(
    (name) => {
      // remove attachment but preserve color if there is a color field
      const deletedFile = { color: story[name]?.color };
      setValue(name, deletedFile);
      dispatch(updateCurrentStoryAction(story, name, deletedFile));
    },
    [dispatch, setValue, story]
  );

  const handleClosePicker = useCallback(() => {
    setPickerOpened(false);
  }, []);

  const handleColorChange = useCallback((color) => {
    setPickerColor(color.hex);
  }, []);

  const handleColorChangeComplete = useCallback(
    (color) => {
      let updateField = changeField;
      let updateValue = color.hex;
      if (changeField.includes('.')) {
        // eslint-disable-next-line prefer-destructuring
        updateField = changeField.split('.')[0];
        updateValue = { ...story[updateField], color: color.hex };
      }
      setValue(changeField, color.hex);
      dispatch(updateCurrentStoryAction(story, updateField, updateValue));
    },
    [changeField, setValue, dispatch, story]
  );

  const handleChangeFont = useCallback(
    (e) => {
      const newFont = e.target.dataset.value;
      dispatch(updateCurrentStoryAction(story, 'fontFamily', newFont));
    },
    [dispatch, story]
  );

  const handleChangeEnlarge = useCallback(
    (e) => {
      dispatch(
        updateCurrentStoryAction(story, e.target.name, !story.enlargeFrame)
      );
    },
    [dispatch, story]
  );

  const handleHideBorders = useCallback(() => {
    setValue('hideBorder', !hideBorder);
    dispatch(updateCurrentStoryAction(story, 'hideBorder', !hideBorder));
  }, [dispatch, setValue, story, hideBorder]);

  const toggleStoryDraft = useCallback(
    (e) => {
      dispatch(updateCurrentStoryAction(story, e.target.name, !story?.draft));
    },
    [story, dispatch]
  );

  return (
    <FormWrap id='settings-form'>
      {isSmallDesktop && (
        <ControlBarBlock blockTitle='General' name='General'>
          <StyledCheckbox
            label='Save as Draft'
            name='draft'
            onChange={toggleStoryDraft}
            checked={!!story?.draft}
          />
        </ControlBarBlock>
      )}
      <ControlBarBlock blockTitle='Layout' name='isLandscape'>
        <Controller
          render={({ fieldState: { error } }) => (
            <StyledInput
              value={story.isLandscape ? 'Landscape' : 'Portrait'}
              label='Orientation'
              error={error ? error.message : ''}
              disabled
            />
          )}
          name='story.orientation'
          control={control}
        />
        <ChangeOrientationDialog
          isOpen={closeDialog}
          handleClose={handleOnCloseDialog}
          handleSubmit={changeOrientation}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Fonts'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              label='System font'
              error={error ? error.message : null}
              {...field}
            >
              {fonts.map((item) => {
                const keys = Object.keys(item);
                return (
                  <MenuItem
                    key={keys[0]}
                    value={item[keys[0]]}
                    onClick={handleChangeFont}
                  >
                    {item[keys[0]]}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='fontFamily'
          control={control}
        />
      </ControlBarBlock>
      {pickerOpened && (
        <ClickAwayListener
          mouseEvent='onMouseUp'
          touchEvent='onTouchStart'
          onClickAway={handleClosePicker}
        >
          <SketchPicker
            disableAlpha
            className={classes.picker}
            color={pickerColor}
            onChange={handleColorChange}
            onChangeComplete={handleColorChangeComplete}
          />
        </ClickAwayListener>
      )}
      <ControlBarBlock blockTitle='Colors' position='relative'>
        <IconButton className={classes.restartIcon} onClick={handleResetColors}>
          <RestartIcon />
        </IconButton>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledColorInput
              fullWidth
              disableUnderline
              disabled
              label='Primary'
              onClick={handleOpenPicker}
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='primaryColor'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledColorInput
              fullWidth
              disableUnderline
              disabled
              label='Secondary'
              onClick={handleOpenPicker}
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='secondaryColor'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledColorInput
              fullWidth
              disableUnderline
              disabled
              label='Page background'
              onClick={handleOpenPicker}
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='backgroundColor'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledColorInput
              fullWidth
              disableUnderline
              disabled
              label='Active frame border'
              onClick={handleOpenPicker}
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='activeFrameBorderColor'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock
        blockTitle='Story background'
        padding={`${spacing(2)}px ${spacing(2)}px 0`}
      >
        <TabsWrapper
          justifyContent='space-between'
          value={selectTab}
          onChange={handleChange}
        >
          <StyledTab label='Image/GIF' maxWidth={80} />
          <StyledTab label='Video' maxWidth={80} />
        </TabsWrapper>
        <TabPanel value={selectTab} index={0}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  story?.backgroundAttachment?.type
                    ? story?.backgroundAttachment?.name
                    : ''
                }
                label='Select file (5mb max.)'
                placeholder='No file'
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                accept={['image/*']}
                onError={setError}
                {...controlProps}
                onDelete={() => handleDelete(controlProps.field.name)}
                onChange={(data) => handleUpload(data, controlProps.field.name)}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  story?.backgroundAttachment?.type
                    ? story?.backgroundAttachment?.name
                    : ''
                }
                label='Select file'
                placeholder='No file'
                accept={['video/*']}
                onError={setError}
                onChange={(data) =>
                  handleUpload(data.original, controlProps.field.name)
                }
                onDelete={() => handleDelete(controlProps.field.name)}
                {...controlProps}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
      </ControlBarBlock>
      <ControlBarBlock
        blockTitle='Icons'
        padding={`${spacing(2)}px ${spacing(2)}px 0`}
      >
        {CUSTOM_ICONS.map((item) => (
          <Fragment key={item.name}>
            <Controller
              render={(controlProps) => (
                <FileInput
                  value={story?.[item.field]?.name || ''}
                  label={`${item.name} icon`}
                  placeholder='No file'
                  accept={['.svg']}
                  onError={setError}
                  {...controlProps}
                  onDelete={() => handleDelete(controlProps.field.name)}
                  onChange={(data) => {
                    handleUpload(data, controlProps.field.name);
                  }}
                />
              )}
              name={item.field}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledColorInput
                  fullWidth
                  disableUnderline
                  disabled
                  label={`${item.name} color`}
                  onClick={handleOpenPicker}
                  error={error ? error.message : ''}
                  {...field}
                  value={field.value || ''}
                />
              )}
              name={`${item.field}.color`}
              control={control}
            />
          </Fragment>
        ))}
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Animations' name='enlargeFrame'>
        <Controller
          name='enlargeFrame'
          control={control}
          render={() => (
            <StyledCheckbox
              label='Enlarge active frame'
              onChange={handleChangeEnlarge}
              name='enlargeFrame'
              checked={!!story?.enlargeFrame}
            />
          )}
          checked={!!story.enlargeFrame}
        />
      </ControlBarBlock>

      <ControlBarBlock blockTitle='Frames' name='isHiddenIndicator'>
        <Controller
          name='hideBorder'
          control={control}
          render={() => (
            <StyledCheckbox
              label='Hide all borders'
              onChange={handleHideBorders}
              value={hideBorder}
              checked={hideBorder || false}
            />
          )}
        />
        <Controller
          name='isHiddenIndicator'
          control={control}
          render={() => (
            <StyledCheckbox
              label="Hide 'Next frame'"
              onChange={() =>
                dispatch(
                  updateCurrentStoryAction(
                    story,
                    'isHiddenIndicator',
                    !story.isHiddenIndicator
                  )
                )
              }
              checked={!!story.isHiddenIndicator}
            />
          )}
        />
        <Controller
          name='showNextFrames'
          control={control}
          render={() => (
            <StyledCheckbox
              label='Show next frames'
              onChange={() =>
                dispatch(
                  updateCurrentStoryAction(
                    story,
                    'showNextFrames',
                    !story.showNextFrames
                  )
                )
              }
              checked={!!story.showNextFrames}
            />
          )}
        />
      </ControlBarBlock>

      <ControlBarBlock blockTitle='Advanced' name='advanced'>
        <Controller
          name='autoFullScreen'
          control={control}
          render={() => (
            <StyledCheckbox
              label='Auto full screen'
              onChange={() =>
                dispatch(
                  updateCurrentStoryAction(
                    story,
                    'autoFullScreen',
                    !story?.autoFullScreen
                  )
                )
              }
              name='autoFullScreen'
              checked={story?.autoFullScreen}
            />
          )}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default SettingsControl;
